<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th width="10%"></vs-th>
        <vs-th sort-key="name">Territories</vs-th>
        <vs-th sort-key="name">Name</vs-th>
        <vs-th sort-key="customer category">Distribution Channel</vs-th>
        <vs-th sort-key="territory">Customer Group</vs-th>
        <vs-th sort-key="territory">Customer</vs-th>
        <vs-th sort-key="valid_from">Valid From</vs-th>
        <vs-th sort-key="valid_to">Valid To</vs-th>
        <vs-th sort-key="status">Status</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td class="whitespace-no-wrap">
            <feather-icon
              title="Edit"
              icon="EditIcon"
              svgClasses="w-5 h-5 hover:text-primary stroke-current"
              class="ml-2"
              @click.stop="handleEdit(data[indextr].id)"
            />
            <!-- <feather-icon
              title="Delete"
              icon="TrashIcon"
              svgClasses="w-5 h-5 hover:text-danger stroke-current"
              class="ml-2"
              @click.stop="handleDelete(data[indextr].id)"
            /> -->
          </vs-td>
          <vs-td :data="data[indextr].name_territory">{{
            data[indextr].name_territory ? data[indextr].name_territory : "ALL"
          }}</vs-td>
          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
          <vs-td :data="data[indextr].customer_category">{{
            data[indextr].customer_category
              ? data[indextr].customer_category
              : "ALL"
          }}</vs-td>
          <vs-td class="d-row" :data="data[indextr].group1">
            <tr>
              <th>
                Group 1 :{{
                  data[indextr].group1 ? data[indextr].group1 : "ALL"
                }}
              </th>
            </tr>
            <tr>
              <th>
                Group 2 :{{
                  data[indextr].group2 ? data[indextr].group2 : "ALL"
                }}
              </th>
            </tr>
            <tr>
              <th>
                Group 3 :{{
                  data[indextr].group3 ? data[indextr].group3 : "ALL"
                }}
              </th>
            </tr>
          </vs-td>
          <vs-td :data="data[indextr].customer">
            {{ data[indextr].customer ? data[indextr].customer : "ALL" }}
          </vs-td>
          <vs-td :data="data[indextr].valid_from">{{
            data[indextr].valid_from | formatDate
          }}</vs-td>
          <vs-td :data="data[indextr].valid_to">{{
            data[indextr].valid_to | formatDate
          }}</vs-td>
          <vs-td :data="data[indextr].status">{{ data[indextr].status }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    baseUrl: {
      type: String,
    },
    title: {
      type: String,
    },
    action: {
      type: String,
    },
  },
  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      table: this.tableState(),
      mutableAction: "",
    };
  },
  methods: {
    tableState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "ID",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      if (active) {
        this.table.order = key;
        this.table.sort = active;
      } else {
        this.table.order = "ID";
        this.table.sort = "desc";
      }
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },

    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/all", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
          },
        })
        .then((resp) => {
          console.log(resp.data);
          if (resp.status == "success") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            console.log(resp.data);
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    // handleDelete(value) {
    //   this.detail = false;
    //   this.id = value;
    //   this.$vs.dialog({
    //     type: "confirm",
    //     color: "danger",
    //     title: `Confirm`,
    //     text: "Please confirm to delete this data",
    //     accept: this.acceptDelete,
    //   });
    // },
    // acceptDelete() {
    //   this.$vs.loading();
    //   this.$http
    //     .delete(this.baseUrl + "/" + this.id)
    //     .then((resp) => {
    //       this.$vs.loading.close();
    //       if (resp.code == 200) {
    //         this.$vs.notify({
    //           color: "success",
    //           title: "Success",
    //           text: "The data was successfully deleted",
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-x-circle",
    //         });
    //       } else {
    //         this.$vs.notify({
    //           color: "danger",
    //           title: "Error",
    //           text: resp.message,
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-x-circle",
    //         });
    //       }
    //       this.getData();
    //     })
    //     .catch((error) => {
    //       this.$vs.loading.close();
    //       console.log(error);
    //     });
    // },
    handleClose() {
      this.$emit("action", "");
    },
    handleEdit(id) {
      console.log("aaaaaaaaa", id);
      this.$router.push({
        name: "product-festives-edit",
        params: { id: id },
      });
    },
  },
  mounted() {},
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(value).add(-7, "h").utc().local().format("DD MMM YYYY");
      }
    },
  },
  watch: {
    action(val) {
      this.getData();
      this.mutableAction = val;
    },
  },
};
</script>
